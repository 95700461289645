<template>
  <base-section
    id="about-our-product"
    align="center"
  >
    <v-avatar rounded="true">
      <v-img
        :src="require('@/assets/paydone/check-dark.png')"
        alt="check"
        outlined="true"
      />
    </v-avatar>
    <base-section-heading
      title="Paydone Solutions"
    />
    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
    }),
    computed: {
      cards () {
        return [
          {
            title: this.$t('sections.aboutourproduct.cards.callout1.title'),
            subtitle: this.$t('sections.aboutourproduct.cards.callout1.subtitle'),
            text: this.$t('sections.aboutourproduct.cards.callout1.text'),
            callout: '01',
          },
          {
            title: this.$t('sections.aboutourproduct.cards.callout2.title'),
            subtitle: this.$t('sections.aboutourproduct.cards.callout2.subtitle'),
            text: this.$t('sections.aboutourproduct.cards.callout2.text'),
            callout: '02',
          },
          {
            title: this.$t('sections.aboutourproduct.cards.callout3.title'),
            subtitle: this.$t('sections.aboutourproduct.cards.callout3.subtitle'),
            text: this.$t('sections.aboutourproduct.cards.callout3.text'),
            callout: '03',
          },
        ]
      },
    },
  }
</script>
